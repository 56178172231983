<template>
  <div class="first-login-view ace-column" @keydown.enter.prevent="onConfirm">
    <InformationMolecule
      header="Lösenordet måste bestå av"
      text="Minst 6 tecken, varav minst 2 bokstäver (a-Z, gemener eller versaler) och minst 2 siffror. Inga skiljetecken eller interpunktionstecken får användas (så som !#-%&_)"
    />
    <div class="first-login-view__input">
      <TextInputAtom
        label="Nytt lösenord"
        :type="showNewPassword ? 'text' : 'password'"
        :value="newPassword"
        :error="fieldErrors['newPassword']"
        @input="newPassword = $event"
      />
      <svg class="first-login-view__input__icon" @click="showNewPassword = !showNewPassword">
        <use v-if="!showNewPassword" xlink:href="#show"></use>
        <use v-if="showNewPassword" xlink:href="#hide"></use>
      </svg>
    </div>
    <div class="first-login-view__input">
      <TextInputAtom
        label="Bekräfta nytt lösenord"
        :type="showConfirmPassword ? 'text' : 'password'"
        :value="confirmPassword"
        :error="fieldErrors['confirmPassword']"
        @input="confirmPassword = $event"
      />
      <svg class="first-login-view__input__icon" @click="showConfirmPassword = !showConfirmPassword">
        <use v-if="!showConfirmPassword" xlink:href="#show"></use>
        <use v-if="showConfirmPassword" xlink:href="#hide"></use>
      </svg>
    </div>
    <FooterMolecule mode="modal" :errors="errors" size="sm">
      <template #buttons>
        <ButtonAtom label="Avbryt" type="secondary" @click="onCancel" />
        <ButtonAtom label="Fortsätt" @click="onConfirm" />
      </template>
    </FooterMolecule>
  </div>
</template>

<script>
import { TextInputAtom, FooterMolecule, InformationMolecule, ButtonAtom } from '@sgf/go-ace-ui';
import logic from './FirstLoginView.logic';
import types from '../../store/types';
import { mapActions } from 'vuex';
export default {
  components: {
    TextInputAtom,
    FooterMolecule,
    InformationMolecule,
    ButtonAtom,
  },
  data() {
    return {
      hasSubmitted: false,
      newPassword: '',
      showNewPassword: true,
      confirmPassword: '',
      showConfirmPassword: true,
    };
  },
  computed: {
    validation() {
      if (!this.hasSubmitted) {
        return undefined;
      }
      return logic.validate(this.newPassword, this.confirmPassword);
    },
    fieldErrors() {
      if (!this.validation) {
        return {};
      }
      return this.validation.reduce((pv, cv) => {
        pv[cv.field] = cv.message;
        return pv;
      }, {});
    },
    errors() {
      if (!this.validation) {
        return undefined;
      }
      return this.validation.map((x) => x.message);
    },
  },
  methods: {
    ...mapActions({
      setField: types.actions.SET_FIELD,
      loginFormSetField: types.actions.LOGIN_FORM_SET_FIELD,
      resetLogin: types.actions.RESET_LOGIN,
    }),
    onConfirm(e) {
      e.preventDefault();
      this.hasSubmitted = true;
      if (this.validation) {
        return;
      }
      this.loginFormSetField({ field: 'newPassword', value: this.newPassword });
      this.setField({ field: 'firstLogin', value: false });
    },
    onCancel(e) {
      e.preventDefault();
      this.resetLogin();
    },
  },
  mounted() {
    this.showNewPassword = false;
    this.showConfirmPassword = false;
  },
};
</script>

<style scoped>
.first-login-view__input {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
  align-items: flex-end;
}

.first-login-view__input :deep(.ace-input) {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.first-login-view__input__icon {
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--ace-c-text-gray);
  padding: 9px;
  background: var(--ace-c-primary-gray);
  border-radius: 0 3px 3px 0;
  cursor: pointer;
}
.first-login-view :deep(input::-ms-reveal),
.first-login-view :deep(input::-webkit-reveal-password-button),
.first-login-view :deep(input::-webkit-credentials-auto-fill-button) {
  display: none;
}
</style>
