<template>
  <div class="freja-login-view ace-column">
    <LoaderAtom mode="block" height="320px" v-if="isLoading" :header="loadingDescription" />
    <InformationMolecule v-if="error" header="OBS!" :text="error" />
    <div class="ace-column freja-login-view__layout" v-if="!isLoading">
      <div class="ace-column freja-login-view__layout" v-if="!error">
        <div class="ace-center">
          <img v-if="qrCodeSrc" :src="qrCodeSrc" />
        </div>
        <div class="freja-login-view__instructions">
          <ol class="freja-login-view__instructions__list">
            <li>Öppna appen för Freja+.</li>
            <li>Tryck på QR-symbolen i appen för Freja+.</li>
            <li>Rikta kameran mot QR-koden ovan.</li>
            <li>Följ instruktionerna i appen för att logga in.</li>
          </ol>
        </div>
      </div>
      <div class="ace-center">
        <ButtonAtom type="secondary" label="Avbryt" @click="resetLogin" />
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonAtom, LoaderAtom, InformationMolecule } from '@sgf/go-ace-ui';
import { mapState, mapActions } from 'vuex';
import types from '../../store/types';
var frejaQREndpoint = process.env.VUE_APP_FREJA_QR_ENDPOINT;
export default {
  components: {
    ButtonAtom,
    LoaderAtom,
    InformationMolecule,
  },
  computed: {
    ...mapState(['frejaAuthRef', 'frejaStatus', 'error']),
    qrCodeSrc() {
      if (this.frejaAuthRef) {
        const urlEncodedAuthRef = encodeURIComponent(this.frejaAuthRef);
        const frejaEidAuhtenticationSchema = 'frejaeid://bindUserToTransaction?transactionReference=' + urlEncodedAuthRef;
        const urlEncodedGenerateQrCodeParameter = encodeURIComponent(frejaEidAuhtenticationSchema);
        return frejaQREndpoint + '/qrcode/generate?qrcodedata=' + urlEncodedGenerateQrCodeParameter;
      }
      return undefined;
    },
    loadingDescription() {
      if (this.frejaStatus) {
        return 'Slutför verifiering,';
      }
      return undefined;
    },
    isLoading() {
      if (this.error) {
        return false;
      }
      if (this.frejaStatus === 'STARTED') {
        return false;
      }
      if (this.frejaStatus !== undefined) {
        return true;
      }
      if (this.frejaAuthRef === undefined) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      resetLogin: types.actions.RESET_LOGIN,
    }),
  },
  mounted() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.freja-login-view__layout {
  grid-template-columns: minmax(0, max-content);
  justify-content: center;
}
.freja-login-view__instructions__list {
  list-style: auto;
}
</style>
