import App from './App.vue';
import store from './store/store.js';
import { createApp } from 'vue';
import { directives as aceDirectives } from '@sgf/go-ace-ui';
import moment from 'moment';

moment.locale('sv');
const app = createApp(App);
app.config.errorHandler = (err) => {
  if (err && !err.code) {
    throw err;
  }
};
app.use(store);
app.use(aceDirectives);
app.mount('#app');
app.config.globalProperties.$moment = moment;
