export default {
  isPasswordLetterValid(password) {
    if (!/(?=.*[a-zA-Z].*[a-zA-Z])/.test(password)) {
      return false;
    }
    return true;
  },
  isPasswordNumberValid(password) {
    if (!/(?=.*[0-9].*[0-9])/.test(password)) {
      return false;
    }
    return true;
  },
  isPasswordLengthValid(password) {
    if (!/[a-zA-Z0-9]{6,}/.test(password)) {
      return false;
    }
    return true;
  },
  isPasswordSpecialCharactersValid(password) {
    if (/.*[\s,?!@#$%^&*()+\-=[\]{}|\\:;"'<>,./`~]/.test(password)) {
      return false;
    }
    return true;
  },
  fieldRequired(field) {
    return { field: field, message: 'Fältet är obligatoriskt' };
  },
  validate(newPassword, confirmPassword) {
    let result = [];

    if (!newPassword) {
      result.push(this.fieldRequired('newPassword'));
    }
    if (!confirmPassword) {
      result.push(this.fieldRequired('confirmPassword'));
    }
    if (newPassword !== confirmPassword) {
      result.push({ field: 'confirmPassword', message: 'Lösenord måste matcha' });
    }
    if (!this.isPasswordLetterValid(newPassword)) {
      result.push({ field: 'newPassword', message: 'Lösenordet måste innehålla minst 2 bokstäver (a-Z)' });
    }
    if (!this.isPasswordNumberValid(newPassword)) {
      result.push({ field: 'newPassword', message: 'Lösenordet måste innehålla minst 2 siffror' });
    }
    if (!this.isPasswordSpecialCharactersValid(newPassword)) {
      result.push({ field: 'newPassword', message: 'Lösenordet får inte innehålla mellanslag eller specialtecken som kommatecken' });
    }
    if (!this.isPasswordLengthValid(newPassword)) {
      result.push({ field: 'newPassword', message: 'Lösenordet måste vara minst 6 tecken långt' });
    }
    if (result.length === 0) {
      return undefined;
    }
    return result;
  },
};
