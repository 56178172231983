<template>
  <div class="selection-molecule ace-shadow" :class="{ 'selection-molecule--is-git': isGitOnline }" @click="$emit('click', type)">
    <div class="selection-molecule__icon" :class="{ 'selection-molecule__icon--is-git': isGitOnline }">
      <svg v-if="isGitOnline">
        <use xlink:href="#local-golf"></use>
      </svg>
      <svg v-if="isStarter">
        <use xlink:href="#local-course"></use>
      </svg>
      <svg v-if="isPartner">
        <use xlink:href="#local-partner"></use>
      </svg>
    </div>
    <div class="selection-molecule__title">
      <h2>{{ title }}</h2>
    </div>
    <div class="selection-molecule__action">
      <svg class="selection-molecule__action__icon">
        <use xlink:href="#arrow-down-secondary"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
  },
  emits: ['click'],
  computed: {
    isGitOnline() {
      return this.type === 'gitonline';
    },
    isStarter() {
      return this.type === 'starter';
    },
    isPartner() {
      return this.type === 'partner';
    },
    title() {
      if (this.isGitOnline) {
        return 'GIT';
      }
      if (this.isStarter) {
        return 'Starter';
      }
      return 'GIT Partnerbokning';
    },
  },
};
</script>

<style scoped>
.selection-molecule {
  display: grid;
  gap: var(--ace-gutter);
  grid-template-columns: minmax(0, max-content) minmax(0, 1fr) minmax(0, max-content);
  background: var(--ace-c-primary-blue-light);
  justify-content: center;
  align-items: center;
  border-radius: 50px 16px 16px 50px;
  cursor: pointer;
}

.selection-molecule:hover {
  opacity: 0.9;
}

.selection-molecule__icon {
  background: var(--ace-c-primary-blue-light);
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 3px solid white;
  padding: 4px;
}

.selection-molecule__icon--is-git,
.selection-molecule--is-git {
  background: var(--ace-c-primary-blue);
}

.selection-molecule__icon svg {
  width: 32px;
  height: 32px;
}

.selection-molecule__action {
  width: 42px;
  height: 42px;
}

.selection-molecule__action__icon {
  transform: rotate(270deg);
  width: 42px;
  height: 42px;
}
</style>
