<template>
  <div class="logo-atom">
    <img class="logo-atom__logo" src="../../../public/sgf-logo.png" onerror="this.style.display='none'" />
    <img class="logo-atom__logo-text" src="../../../public/sgf-logo-text.png" onerror="this.style.display='none'" />
  </div>
</template>

<script>
export default {
  name: 'LogoAtom',
};
</script>

<style scoped>
.logo-atom {
  position: fixed;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.logo-atom__logo {
  max-width: 60px;
  padding-bottom: 16px;
}
.logo-atom__logo-text {
  width: 150px;
}

@media (max-width: 768px) {
  .logo-atom {
    top: unset;
    bottom: 16px;
  }
}
</style>
