var state = {
  loading: undefined,
  error: undefined,
  loginForm: {
    golfId: '',
    password: '',
    newPassword: undefined,
    submitted: false,
    isStarter: false,
  },
  clubForm: {
    clubs: [],
    selectedClub: '',
  },
  passwordForm: {
    isActive: false,
    submitted: false,
    golfId: '',
    email: '',
    isGolfIdValid: true,
    isEmailValid: true,
    passwordSent: false,
  },
  accessToken: undefined,
  firstLogin: false,
  frejaLogin: false,
  frejaAuthRef: undefined,
  frejaStatus: undefined,
  frejaToken: undefined,
  clubChanged: false,
};

export default state;
