var apiBase = process.env.VUE_APP_APIENDPOINT;
const call = async (uri, method, payload, accessToken) => {
  const response = await fetch(apiBase + uri, {
    method: method,
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',

      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
    body: JSON.stringify(payload),
  });
  if (response.status === 204) {
    return;
  }
  if (response.ok) {
    return await response.json();
  }

  let error;
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('application/json')) {
    try {
      let jsonResponse = await response.json();
      if (typeof jsonResponse === 'object') {
        error = JSON.stringify(jsonResponse);
      } else {
        error = jsonResponse;
      }
    } catch {
      throw {};
    }
  } else {
    error = await response.text();
  }
  if (!error) {
    error = response.status;
  }
  return Promise.reject({ message: error, status: response.status });
};

export const initializeAuthentication = (loginForm, frejaToken) => {
  if (frejaToken) {
    return call('clubs', 'POST', {
      FrejaToken: frejaToken,
    });
  } else {
    const { golfId, password } = loginForm;
    const payload = {
      Username: golfId,
      Password: password,
    };
    return call('clubs', 'POST', payload);
  }
};

export const authenticate = (loginForm, clubForm, accessToken, frejaToken) => {
  const { selectedClub } = clubForm;
  if (frejaToken) {
    const payload = {
      FrejaToken: frejaToken,
      OrganizationId: selectedClub,
    };
    return call('login', 'POST', payload, accessToken);
  } else {
    const { golfId, password, newPassword } = loginForm;
    const payload = {
      Username: golfId,
      Password: password,
      OrganizationId: selectedClub,
      NewPassword: newPassword,
    };
    return call('login', 'POST', payload, accessToken);
  }
};

export const forgotPassword = async (passwordForm) => {
  try {
    const { golfId, email } = passwordForm;
    const payload = {
      Username: golfId,
      Email: email,
    };
    await call('user/changepassword', 'POST', payload);
  } catch (err) {
    throw { message: `Kunde inte skicka ett nytt lösenord till angiven epostadress. (${err.message})` };
  }
};

export const frejaInitAuth = () => {
  return call('freja/initAuthentication', 'GET');
};

export const frejaAuthenticationResult = (authRef) => {
  return call('freja/authenticationResult', 'POST', authRef);
};
