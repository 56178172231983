<template>
  <div class="password-form-view ace-column">
    <TextInputAtom
      label="Golf-ID"
      :value="getPasswordForm.golfId"
      @input="onGolfIdChange"
      mask="######-###"
      type="tel"
      placeholder="______-___"
      :error="passwordValidation['golfId']"
    />
    <TextInputAtom label="E-post" :value="getPasswordForm.email" @input="onEmailChange" :error="passwordValidation['email']" />
    <div class="ace-row password-form-view__action-panel">
      <ButtonAtom label="Skicka" @click="onSendClick" />
      <ButtonAtom type="secondary" label="Avbryt" @click="onCancelClick" />
    </div>
    <ValidationAtom v-if="error" :value="error" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import types from '../../store/types';
import { TextInputAtom, ButtonAtom, ValidationAtom } from '@sgf/go-ace-ui';

export default {
  name: 'PasswordFormView',
  components: {
    TextInputAtom,
    ButtonAtom,
    ValidationAtom,
  },
  computed: {
    ...mapState(['error']),
    ...mapGetters({
      getPasswordForm: types.getters.GET_PASSWORD_FORM,
      passwordValidation: types.getters.RESET_PASSWORD_VALIDATION,
    }),
  },
  methods: {
    ...mapActions({
      passwordFieldSetField: types.actions.PASSWORD_FORM_SET_FIELD,
      sendNewPassword: types.actions.SEND_NEW_PASSWORD,
      resetLogin: types.actions.RESET_LOGIN,
    }),
    onGolfIdChange(val) {
      this.updatePasswordFormValue('golfId', val);
    },
    onEmailChange(val) {
      this.updatePasswordFormValue('email', val);
    },
    updatePasswordFormValue(target, value) {
      this.passwordFieldSetField({
        field: target,
        value: value,
      });
    },
    onCancelClick(e) {
      e.preventDefault();
      this.resetLogin();
    },

    onSendClick(e) {
      e.preventDefault();
      this.sendNewPassword();
    },
  },
  mounted() {
    if (this.$refs.golfId) {
      this.$refs.golfId.$refs.input.focus();
    }
  },
};
</script>

<style scoped>
.password-form-view__action-panel {
  flex-direction: row-reverse;
}

.password-form-view :deep(.validation-atom__value) {
  overflow: unset;
  text-overflow: unset;
  white-space: break-spaces;
}
</style>
