import types from './types';

function _isEmailValid(state) {
  var pattern = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$');
  var isValid = pattern.test(state.passwordForm.email);
  return isValid;
}

var getters = {
  [types.getters.GET_CLUBS]: (state) => {
    return state.clubForm.clubs.map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  },
  [types.getters.GET_LOGIN_FORM]: (state) => state.loginForm,
  [types.getters.GET_LOADER]: (state) => state.loader,
  [types.getters.GET_MESSAGE]: (state) => state.information,
  [types.getters.GET_CLUB_FORM]: (state) => state.clubForm,
  [types.getters.GET_PASSWORD_FORM]: (state) => state.passwordForm,
  [types.getters.GET_CLUB_NAME]: (state) => {
    var club = Object.values(state.clubForm.clubs).find(function (c) {
      return c.id === state.clubForm.selectedClub;
    });
    if (!club) {
      return '';
    }
    return club.name;
  },
  [types.getters.LOGIN_VALIDATION]: (state) => {
    if (!state.loginForm.submitted) {
      return {};
    }
    let result = {};
    if (state.loginForm.golfId.length !== 10) {
      result['golfId'] = 'Golf-ID måste innehålla nio siffror';
    }
    return result;
  },
  [types.getters.RESET_PASSWORD_VALIDATION]: (state) => {
    if (!state.passwordForm.submitted) {
      return {};
    }
    let result = {};

    if (state.passwordForm.golfId.length !== 10) {
      result['golfId'] = 'Golf-ID måste innehålla nio siffror';
    }
    if (!_isEmailValid(state)) {
      result['email'] = 'E-post är felaktig';
    }
    return result;
  },
};

export default getters;
