<template>
  <div class="club-form-view ace-column">
    <DropdownAtom mode="fullwidth" label="Klubb" :options="clubs" :value="selectedClub" @input="selectedClub = $event" />
    <ValidationAtom v-if="error" :value="error" />
    <div class="club-form-view__action-panel ace-row">
      <ButtonAtom @click="login" ref="confirm">Välj</ButtonAtom>
      <ButtonAtom type="secondary" @click="onCancel">Avbryt</ButtonAtom>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import types from '../../store/types';
import { DropdownAtom, ButtonAtom, ValidationAtom } from '@sgf/go-ace-ui';
export default {
  name: 'ClubFormView',
  components: {
    DropdownAtom,
    ButtonAtom,
    ValidationAtom,
  },
  computed: {
    ...mapState(['error']),
    ...mapGetters({
      clubForm: types.getters.GET_CLUB_FORM,
      clubs: types.getters.GET_CLUBS,
    }),
    selectedClub: {
      get() {
        return this.clubForm.selectedClub;
      },
      set(value) {
        this.selectClub(value);
      },
    },
  },
  methods: {
    ...mapActions({
      selectClub: types.actions.SELECT_CLUB,
      resetLogin: types.actions.RESET_LOGIN,
      login: types.actions.LOGIN,
    }),
    onCancel(e) {
      e.preventDefault();
      this.resetLogin();
    },

    handleEnter(e) {
      if (e.key === 'Enter') {
        this.login();
      }
    },
  },
  mounted() {
    if (Object.keys(this.clubForm.clubs).length === 1 && this.clubForm.selectedClub) {
      this.$emit('processForm');
    }

    window.addEventListener('keyup', this.login);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.login);
  },
  emits: ['processForm'],
};
</script>
<style scoped>
.club-form-view__action-panel {
  flex-direction: row-reverse;
}
</style>
