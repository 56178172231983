<template>
  <div class="selection-view">
    <SelectionIconsAtom />
    <div class="selection-view__choices ace-column">
      <SelectionMolecule type="gitonline" @click="onClick" />
      <p class="selection-view__choices__divider">- Eller -</p>
      <SelectionMolecule type="starter" @click="onClick" />
      <SelectionMolecule type="partner" @click="onClick" />
    </div>
  </div>
</template>

<script>
import { SelectionIconsAtom } from '../atoms';
import SelectionMolecule from '../molecules/SelectionMolecule';
import { mapActions } from 'vuex';
import types from '../../store/types';
export default {
  components: {
    SelectionIconsAtom,
    SelectionMolecule,
  },
  methods: {
    ...mapActions({
      frejaInitAuthentication: types.actions.FREJA_INIT_AUTHENTICATION,
      setField: types.actions.SET_FIELD,
    }),
    onClick(type) {
      if (type === 'starter') {
        this.setField({
          field: 'isStarter',
          value: true,
        });
      }
      if (type === 'partner') {
        return (window.location.href = '/partner-booking/');
      }
      this.frejaInitAuthentication();
    },
  },
};
</script>

<style scoped>
.selection-view__choices__divider {
  font-family: BrixSansBold;
  text-align: center;
}
</style>
