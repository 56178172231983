<template>
  <div class="system-message-organism" v-if="hasMessage">
    <InformationMolecule header="OBS!" :text="message.message" />
  </div>
</template>

<script>
import { InformationMolecule } from '@sgf/go-ace-ui';
export default {
  props: {
    app: String,
  },
  components: {
    InformationMolecule,
  },
  data() {
    return {
      message: undefined,
    };
  },
  computed: {
    isCritical() {
      if (this.hasMessage && this.message.severity === 'critical') {
        return true;
      }
      return false;
    },
    hasMessage() {
      if (!this.message) {
        return false;
      }
      if (!this.message.message) {
        return false;
      }
      if (this.message.message === '') {
        return false;
      }
      return true;
    },
  },
  methods: {
    async fetchStatus() {
      let response = await fetch(`../sysadmin/api/messages/${this.app}`, {
        method: 'GET',
      });
      try {
        if (response.status === 200) {
          let result = await response.json();
          this.message = result;
        }
      } catch (e) {
        this.message = undefined;
      }
    },
  },
  mounted() {
    this.fetchStatus();
    setInterval(this.fetchStatus, 1000 * 60 * 5);
  },
};
</script>

<style scoped>
.system-message-organism {
  width: 100%;
}
</style>
